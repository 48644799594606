import request from "@/utils/request";
// 俱乐部列表
const getClubList = (params) =>
  request.post("/boxing/web/club/clubAndApprovalList", params);
// 审核俱乐部
const setCheckClub = (params) =>
  request.post("/boxing/web/club/clubApproval", params);
// 修改俱乐部状态
const setClubStatus = (params) =>
  request.post("/boxing/web/club/modifyClubStatus", params);
// 获取俱乐部详情
const getClubDetail = (params) =>
  request.post("/boxing/web/club/selectClubDetail", params);
// 俱乐部信息变更列表
const getModifyList = (params) =>
  request.post("/boxing/web/club/pageClubInfoModify", params);
// 俱乐部信息变更详情
const getModifyDetail = (params) =>
  request.post("/boxing/web/club/selectClubInfoModify", params);
// 审核俱乐部信息变更
const setModifyStatus = (params) =>
  request.post("/boxing/web/club/auditClubInfoModify", params);
export {
  getClubList,
  setCheckClub,
  setClubStatus,
  getClubDetail,
  getModifyList,
  getModifyDetail,
  setModifyStatus,
};
